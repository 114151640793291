import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import SectionTitle from '../../components/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';

import teamImg1 from '../../assets/img/team/1.png';
import teamImg2 from '../../assets/img/team/2.png';
import teamImg3 from '../../assets/img/team/3.png';
import teamImg4 from '../../assets/img/team/4.png';
import teamImg5 from '../../assets/img/team/5.png';
import teamImg6 from '../../assets/img/team/6.png';
import teamImg7 from '../../assets/img/team/7.png';
import teamImg8 from '../../assets/img/team/8.png';
import teamImg9 from '../../assets/img/team/9.png';
import teamImg10 from '../../assets/img/team/10.png';
import teamImg11 from '../../assets/img/team/11.png';
import teamImg12 from '../../assets/img/team/12.png';
import teamImg13 from '../../assets/img/team/13.png';
import teamImg14 from '../../assets/img/team/14.png';
import teamImg15 from '../../assets/img/team/15.png';
import teamImg16 from '../../assets/img/team/16.png';
import teamImg17 from '../../assets/img/team/17.png';
import teamImg18 from '../../assets/img/team/18.png';
import teamImg19 from '../../assets/img/team/19.png';
import teamImg20 from '../../assets/img/team/20.png';
import teamImg21 from '../../assets/img/team/21.png';

const TeacherMain = () => {
  return (
    <main>
      <Breadcrumb title="Faculties" />

      <div className="ed-team-area p-relative inner-style fix z-index pt-110 pb-90">
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-6">
                <SectionTitle
                  itemClass="it-team-title-box text-center"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Faculties"
                  titleClass="ed-section-title"
                  title="Meet Our Instructor"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg1}
                authorName="Prof.Mettilda B"
                designation="Principal"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg2}
                authorName="Prof.Santhi S"
                designation="Vice Principal"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg3}
                authorName="Mrs.Latha R"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg4}
                authorName="Sr.Sagaya Mary A"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg5}
                authorName="Sr.Kanikkai Parvin Mary N"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg6}
                authorName="Mrs.Nancy Seema J"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg7}
                authorName="Mrs. Arul Diana S"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg8}
                authorName="Mrs.Susan Deepthi A"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg9}
                authorName="Mrs.Narkis A"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg10}
                authorName="Ms.Franklin Lidiya L"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg11}
                authorName="Mrs.Senthamil Selvi N"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg12}
                authorName="Mrs.Priyankasopra K"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg13}
                authorName="Mrs.Renuga R"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg14}
                authorName="Mrs.Tamilselvi K"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg15}
                authorName="Mrs.Christina Anitha S"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg16}
                authorName="Mrs.Sathiyapriya V"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg17}
                authorName="Ms.Shiny Gladiya J"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg18}
                authorName="Ms.Kaviarasi Sarvarani K"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg19}
                authorName="Ms.Agnas Nancy R"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg20}
                authorName="Mrs.Nivetha A"
                designation="Teacher"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg21}
                authorName="Ms.Flora Josphine J"
                designation="Teacher"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default TeacherMain;
