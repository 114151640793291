import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitleSpecial from '../../components/SectionTitle/SectionTitleSpecial';
import RightArrow from '../../components/SVG';

import shapeImg1 from '../../assets/img/about/shape-1-4.png';
import shapeImg2 from '../../assets/img/about/ed-shape-1-1.png';
import aboutImg1 from '../../assets/img/about/homeabout1.png';
import aboutImg2 from '../../assets/img/about/homeabout2.png';
import aboutImg3 from '../../assets/img/about/homeabout3.png';

const About = () => {
  return (
    <div
      id="it-about"
      className="it-about-area ed-about-style-2 p-relative pt-185 pb-185"
    >
      <div className="it-about-shape-4 d-none d-md-block">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <div className="ed-about-thumb-box p-relative">
              <div className="ed-about-thumb-1">
                <img src={aboutImg1} alt="" />
              </div>
              <div className="ed-about-thumb-2">
                <img src={aboutImg2} alt="" />
              </div>
              <div className="ed-about-thumb-3">
                <img src={aboutImg3} alt="" />
              </div>
              <div className="ed-about-shape-1 d-none d-md-block">
                <img src={shapeImg2} alt="" />
              </div>
              <div className="ed-about-experience d-none d-md-block">
                <span>
                  <b>16+</b> <br />
                  Years Of <br />
                  Experiences
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration=".9s"
            data-wow-delay=".7s"
          >
            <div className="it-about-right-box">
              <SectionTitleSpecial
                itemClass="it-about-title-box mb-20 section-title-fixed-width"
                subTitle="Our about us"
                preTitle="Learn and Grow Your"
                highlightText="Skills"
                postTitle="Anywhere"
              />

              <div className="it-about-text pb-10">
                <p>
                At Servite College of Nursing, we empower young women through quality nursing education, preparing them to meet the healthcare needs of society. As a Catholic institution led by the Congregation of Mother of Sorrows, Servants of Mary, we uphold a legacy of service dating back to 1854, with a presence in Australia, Italy, Myanmar, and the Philippines. Guided by our patron Mary, who symbolizes women’s liberation, we are committed to fostering the well-being and growth of women and children in various fields. Inspired by the healing ministry of Jesus Christ, our mission is to train skilled health professionals who can make a meaningful impact in the community.
                </p>
              </div>
              <div className="it-about-content-wrapper d-flex align-items-center justify-content-between pb-15">
                <div className="it-about-content">
                  <h5>Mission</h5>
                  <p>
                  Our mission is to empower women to live dignified lives by providing comprehensive nursing education, guided by the compassionate model of Mary, our mother.
                  </p>
                </div>
                <div className="it-about-content">
                  <h5>Vision</h5>
                  <p>
                  We strive to elevate women's lives through medical education, continually adapting our programs to meet the evolving needs of healthcare and society.
                  </p>
                </div>
              </div>
              {/* <Link className="ed-btn-theme" to="/about-us">
                Load More
                <i>
                  <RightArrow />
                </i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
