import React from 'react';
import Footer from '../../components/Footer';
import HeaderFive from '../../components/Header/HeaderFive';
import EventMain from './EventMain';

import Logo from '../../assets/img/logo/logo.png';

const Event = () => {
  return (
    <>
      <HeaderFive />

      <EventMain />

      <Footer />
    </>
  );
};
export default Event;
