import React from 'react';

const SingleCategory = (props) => {
  const { itemClass, icon, title } = props;

  return (
    <div
      className={
        itemClass
          ? itemClass
          : 'ed-category-item ed-category-style-1 d-flex align-items-center mb-35'
      }
    >
      <div className="ed-category-icon">
        <span>
          {icon ? (
            icon
          ) : (
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.9999 22V20.6666C36.3679 20.6666 36.6666 20.3682 36.6666 20V16C36.6666 15.6317 36.3679 15.3333 35.9999 15.3333H32.3999L32.2708 14.8325C31.976 13.6872 31.5182 12.5901 30.9106 11.5752L30.644 11.1291L33.1999 8.56873C33.4572 8.31092 33.4572 7.89321 33.1999 7.6354L30.3687 4.79999C30.1072 4.55077 29.6966 4.55077 29.4351 4.79999L26.8744 7.36066L26.4286 7.09399C25.4122 6.48618 24.314 6.02837 23.1671 5.73332L22.6666 5.60311V2C22.6666 1.63177 22.3682 1.33333 22 1.33333H18C17.6317 1.33333 17.3333 1.63177 17.3333 2V5.59999L16.8325 5.72941C15.6872 6.02394 14.5901 6.48176 13.5747 7.08853L13.1286 7.35545L10.5687 4.79999C10.307 4.54869 9.89373 4.54869 9.63202 4.79999L6.79999 7.63123C6.67551 7.75545 6.60546 7.9242 6.60546 8.09998C6.60546 8.27602 6.67551 8.44451 6.79999 8.56873L9.36066 11.1291L9.094 11.5752C8.48671 12.5901 8.02863 13.6872 7.7341 14.8325L7.60337 15.3333H4C3.63177 15.3333 3.33333 15.6317 3.33333 16V20C3.33333 20.3682 3.63177 20.6666 4 20.6666V22C2.89557 22 2 21.1044 2 20V16C2 14.8955 2.89557 14 4 14H6.57942C6.85442 13.0781 7.22629 12.188 7.68879 11.3448L5.85546 9.51144C5.48046 9.13722 5.26978 8.62941 5.26978 8.09998C5.26978 7.57056 5.48046 7.06274 5.85546 6.68879L8.68879 3.85546C9.47811 3.09999 10.7226 3.09999 11.512 3.85546L13.3448 5.68801C14.188 5.22577 15.0784 4.85468 16 4.57994V2C16 0.895571 16.8955 0 18 0H22C23.1044 0 24 0.895571 24 2V4.57994C24.9218 4.85468 25.8117 5.22655 26.6552 5.68879L28.4885 3.85546C29.2781 3.09999 30.5223 3.09999 31.3119 3.85546L34.1447 6.68801C34.5195 7.06222 34.7299 7.56951 34.7299 8.0992C34.7299 8.62889 34.5195 9.1367 34.1447 9.51066L32.3114 11.344C32.7736 12.1877 33.1452 13.0781 33.4205 14H35.9999C37.1044 14 37.9999 14.8955 37.9999 16V20C37.9999 21.1044 37.1044 22 35.9999 22Z"
                fill="#1B75E8"
              />
              <path
                d="M29.3336 17.9993H28.0003C28.0003 13.5811 24.4185 9.99935 20.0003 9.99935C15.5821 9.99935 12.0003 13.5811 12.0003 17.9993H10.667C10.667 12.8447 14.8456 8.66602 20.0003 8.66602C25.1547 8.66602 29.3336 12.8447 29.3336 17.9993Z"
                fill="#1B75E8"
              />
              <path
                d="M20 23.334C17.7909 23.334 16 21.5428 16 19.334C16 17.1249 17.7909 15.334 20 15.334C22.2094 15.334 24 17.1249 24 19.334C23.9976 21.5423 22.2083 23.3316 20 23.334ZM20 16.6673C18.5273 16.6673 17.3333 17.8613 17.3333 19.334C17.3333 20.8066 18.5273 22.0006 20 22.0006C21.4726 22.0006 22.6667 20.8066 22.6667 19.334C22.6667 17.8613 21.4726 16.6673 20 16.6673Z"
                fill="#1B75E8"
              />
              <path
                d="M31.333 26C29.1239 26 27.333 24.2088 27.333 22C27.333 19.7909 29.1239 18 31.333 18C33.5421 18 35.333 19.7909 35.333 22C35.3306 24.2083 33.5413 25.9976 31.333 26ZM31.333 19.3333C29.8603 19.3333 28.6663 20.5273 28.6663 22C28.6663 23.4726 29.8603 24.6667 31.333 24.6667C32.8057 24.6667 33.9997 23.4726 33.9997 22C33.9997 20.5273 32.8057 19.3333 31.333 19.3333Z"
                fill="#1B75E8"
              />
              <path
                d="M8.66698 26C6.45787 26 4.66699 24.2088 4.66699 22C4.66699 19.7909 6.45787 18 8.66698 18C10.8764 18 12.667 19.7909 12.667 22C12.6646 24.2083 10.8753 25.9976 8.66698 26ZM8.66698 19.3333C7.19433 19.3333 6.00032 20.5273 6.00032 22C6.00032 23.4726 7.19433 24.6667 8.66698 24.6667C10.1396 24.6667 11.3336 23.4726 11.3336 22C11.3336 20.5273 10.1396 19.3333 8.66698 19.3333Z"
                fill="#1B75E8"
              />
              <path
                d="M37.377 27.7104C36.3835 27.0276 35.2057 26.6633 33.9999 26.6667H28.6666C28.3424 26.6696 28.0187 26.699 27.6992 26.7545C27.2726 26.0873 26.7177 25.5118 26.0666 25.0605C25.969 24.9868 25.8664 24.9198 25.7593 24.8607C24.8268 24.2959 23.7567 23.9985 22.6666 24H17.3333C15.2945 23.9927 13.3948 25.0331 12.3026 26.7545C11.9823 26.699 11.658 26.6696 11.3333 26.6667H5.99999C4.7953 26.6633 3.6177 27.0271 2.62473 27.7091C0.983592 28.8258 0.00104166 30.6818 0 32.6667V35.3333C0.00234373 37.1537 1.06146 38.8068 2.71484 39.5693C3.32525 39.8568 3.99218 40.0039 4.66666 40H11.3333V38.6667H5.33332V31.3334H3.99999V38.5997C3.74895 38.5495 3.5052 38.4682 3.27395 38.3586C2.09296 37.8141 1.33593 36.6336 1.33333 35.3333V32.6667C1.33489 31.1219 2.10026 29.6779 3.37734 28.8091C4.14895 28.2795 5.06379 27.9974 5.99999 28H11.3333C11.4508 28 11.564 28.0125 11.6786 28.0203C11.4531 28.6563 11.3362 29.3255 11.3333 30V36.6667C11.3357 38.5068 12.8268 39.9977 14.6666 40H16.6666V29.3334H15.3333V38.6667H14.6666C13.5622 38.6667 12.6666 37.7711 12.6666 36.6667V30C12.6666 27.4224 14.7562 25.3334 17.3333 25.3334H22.6666C23.5244 25.3339 24.3651 25.5714 25.0966 26.0193C25.1643 26.0563 25.2294 26.098 25.2911 26.1438C25.9158 26.5716 26.4286 27.1433 26.7859 27.8104C27.145 28.4844 27.3333 29.2362 27.3333 30V36.6667C27.3333 37.7711 26.4377 38.6667 25.3333 38.6667H24.6666V29.3334H23.3333V40H25.3333C27.1734 39.9977 28.6643 38.5068 28.6666 36.6667V30C28.6635 29.3255 28.5471 28.6565 28.3218 28.0203C28.4367 28.0125 28.5499 28 28.6666 28H33.9999C34.9366 27.9974 35.8523 28.2802 36.6244 28.8104C37.9004 29.6797 38.6648 31.1227 38.6666 32.6667V35.3333C38.6642 36.6383 37.9015 37.8221 36.7148 38.3638C36.4869 38.4716 36.2468 38.5508 35.9999 38.5997V31.3334H34.6666V38.6667H28.6666V40H35.3333C36.0038 40.0042 36.6669 39.8589 37.2739 39.5745C38.9327 38.8143 39.9973 37.1581 39.9999 35.3333V32.6667C39.9989 30.6826 39.0171 28.8274 37.377 27.7104Z"
                fill="#1B75E8"
              />
              <path
                d="M17.333 38.666H22.6663V39.9993H17.333V38.666Z"
                fill="#1B75E8"
              />
            </svg>
          )}
        </span>
      </div>
      <h4 className="ed-category-title">
        {title ? title : 'Business Management'}
      </h4>
    </div>
  );
};
export default SingleCategory;
