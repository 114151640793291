import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SingleEvent = (props) => {
  const {
    itemClass,
    eventImage,
    eventDate,
    eventMonth,
    title,
    description,
    eventTime,
    eventLocation,
  } = props;

  // State for managing the modal visibility and the selected image
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to open the modal with the selected image
  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className={itemClass ? itemClass : 'it-event-2-item-box'}>
      <div className="it-event-2-item">
        <div className="it-event-2-thumb fix">
          <img
            src={eventImage ? eventImage : '../../assets/img/event/event-3-1.jpg'}
            alt=""
            onClick={() => openModal(eventImage)}
            style={{ cursor: 'pointer' ,borderRadius:  '10px'}}
          />
          {/* <div className="it-event-2-date">
            <span>
              <i>{eventDate ? eventDate : '08'}</i> <br />
              {eventMonth ? eventMonth : 'October'}
            </span>
          </div> */}
        </div>
        {/* <div className="it-event-2-content">
          <h4 className="it-event-2-title">
            <Link to="/event-details">
              {title
                ? title
                : 'print, and publishing industries for previewing'}
            </Link>
          </h4>
          <div className="it-event-2-text">
            <p className="mb-0 pb-10">
              {description
                ? description
                : 'Lorem ipsum dolor sit amet, consectetur elit, sed doeiusmod tempor'}
            </p>
          </div>
          <div className="it-event-2-meta">
            <span>
              <i className="fa-light fa-clock"></i>
              Time: {eventTime ? eventTime : '11:00am 03:00pm'}
            </span>
            <span>
              <i className="fa-light fa-location-dot"></i>
              Location: {eventLocation ? eventLocation : 'USA'}
            </span>
          </div>
        </div> */}
      </div>

      {/* Modal for displaying the selected image */}
      {isModalOpen && (
        <div className="image-modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>
              Close
            </button>
            <img src={selectedImage} alt="Large View" className="large-image" />
          </div>
        </div>
      )}

      {/* CSS styles */}
      <style jsx>{`
        .image-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 8px;
          text-align: center;
          position: relative;
        }
        .large-image {
          max-width: 100%;
          max-height: 100vh;
          border-radius: 20px;
        }
        .close-btn {
          position: absolute;
          top: 30px;
          right: 30px;
          background-color: #f44336;
          color: white;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 14px;
        }
        .close-btn:hover {
          background-color: #d32f2f;
        }
      `}</style>
    </div>
  );
};

export default SingleEvent;
