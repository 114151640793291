import React from 'react';
import { Link } from 'react-router-dom';
import RightArrow from '../../components/SVG';

import aboutImg1 from '../../assets/img/about/about1.png';
import aboutImg2 from '../../assets/img/about/about2.png';
import aboutImg3 from '../../assets/img/about/about3.png';
import shapeImg1 from '../../assets/img/about/ed-shape-2-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-2-2.png';
import titleImg from '../../assets/img/about/title-home2.png';

const About = () => {
  return (
    <div id="it-about" className="it-about-3-area fix pt-120 pb-120 p-relative">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <div className="ed-about-2-left p-relative text-end">
              <div className="ed-about-2-left-box d-inline-flex align-items-end">
                <div className="ed-about-2-thumb-one pb-110 mr-20">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="ed-about-2-thumb-two text-start">
                  <img className="mb-20 inner-top-img" src={aboutImg2} alt="" />
                  <img src={aboutImg3} alt="" />
                </div>
              </div>
              <div className="ed-about-2-thumb-shape-1 d-none lg-block">
                <img src={shapeImg1} alt="" />
              </div>
              <div className="ed-about-2-thumb-shape-2 d-none d-xxl-block">
                <img src={shapeImg2} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration=".9s"
            data-wow-delay=".7s"
          >
            <div className="it-about-3-title-box">
              <span className="it-section-subtitle-3">
                <img src={titleImg} alt=""></img> about us
              </span>
              <h2 className="it-section-title-3 pb-30">
              Learn and Grow Your Skills{' '}
                <span>Anywhere</span>
              </h2>
              <p>
              Servite College of Nursing, a Catholic institution of higher education for women, is operated by the Congregation of Mother of Sorrows, Servants of Mary. This indigenous Congregation, founded in 1854, has a rich international presence, serving in countries like Australia, Italy, Myanmar, and the Philippines, and holds Pontifical status. The Congregation, inspired by Mary at the foot of the Cross, works for the liberation and empowerment of women and children in various spheres.

The college is one of many institutions run by the Congregation, dedicated to fulfilling Jesus Christ's healing ministry. Servite College of Nursing aims to empower young women by preparing them as health professionals, who are equipped to address society’s healthcare needs.
                <br />
                <br />
                Recognitions: 
                <br />
                <br />
                * The College is approved by the Government of Tamil Nadu (Order No.(Ms).No. 37/H&F.W(PME) Department dated 31.03.2008). <br />
* It is affiliated with The Tamil Nadu Dr. M.G.R. Medical University, Chennai (Order Proc. No.Affln.ll(1)4516/08). <br />
* Recognized by the Tamil Nadu Nurses and Midwifery Council (TNC) (Order No. 404/NC/2008 dated 03.06.2008). <br />
* Recognized by the Indian Nursing Council, New Delhi (Order F.No. 02/May/2008 NO dated 30.05.2008). <br />
Through its mission, Servite College of Nursing continues to promote the dignity of life and contribute to the holistic development of healthcare professionals.
              </p>
            </div>
            <div className="it-about-3-mv-box">
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  <div className="it-about-3-mv-item">
                    <span className="it-about-3-mv-title">OUR MISSION:</span>
                    <p>
                    Our mission is to empower women to live dignified lives by providing comprehensive nursing education, guided by the compassionate model of Mary, our mother.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="it-about-3-mv-item">
                    <span className="it-about-3-mv-title">OUR VISSION:</span>
                    <p>
                    We strive to elevate women's lives through medical education, continually adapting our programs to meet the evolving needs of healthcare and society.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="it-about-3-btn-box p-relative">
              <Link className="ed-btn-theme theme-2" to="/about-us">
                Admission open
                <i>
                  <RightArrow />
                </i>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
