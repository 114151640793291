import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import SingleEvent from '../../components/Event';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import lamp1 from '../../assets/img/event/lamp1.png';
import lamp2 from '../../assets/img/event/lamp3.png';
import lamp3 from '../../assets/img/event/lamp4.png';
import lamp4 from '../../assets/img/event/lamp5.png';
import lamp5 from '../../assets/img/event/lamp6.png';
import lamp6 from '../../assets/img/event/lamp7.png';
import lamp7 from '../../assets/img/event/lamp8.png';
import lamp8 from '../../assets/img/event/lamp2.png';
import woman1 from '../../assets/img/event/woman1.png';
import woman2 from '../../assets/img/event/woman2.png';
import woman3 from '../../assets/img/event/woman3.png';
import woman4 from '../../assets/img/event/woman4.png';
import anti1 from '../../assets/img/event/anti1.png';
import anti2 from '../../assets/img/event/anti2.png';
import anti3 from '../../assets/img/event/anti3.png';
import anti4 from '../../assets/img/event/anti4.png';

const EventMain = () => {
  return (
    <main>
      <Breadcrumb title="Gallery" />
            <div className="it-event-2-area it-event-style-3 p-relative pt-90 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-8">
              <Tabs className="it-course-details-wrap">

                <div className="it-course-details-nav pb-60">
                  <nav>
                    <TabList
                      className="nav nav-tab"
                      id="nav-tab"
                      role="tablist"
                    >
                      <Tab>
                        <button>LAMPLIGHTING CEREMONY</button>
                      </Tab>
                      <Tab>
                        <button>WOMEN'S DAY</button>
                      </Tab>
                      <Tab>
                        <button>ANTI RAGGING</button>
                      </Tab>
                    </TabList>
                  </nav>
                </div>
                <div className="it-course-details-content">
                  <div className="tab-content" id="nav-tabContent">
                  <TabPanel>
  <div className="it-course-details-wrapper">
    <div className="it-evn-details-text mb-40">
    <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp1}
                eventDate="08"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp2}
                eventDate="05"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp3}
                eventDate="25"
                eventMonth="April"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp4}
                eventDate="08"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp5}
                eventDate="05"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp6}
                eventDate="25"
                eventMonth="April"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp7}
                eventDate="25"
                eventMonth="April"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={lamp8}
                eventDate="25"
                eventMonth="April"
                title="print, and publishing industries for previewing"
              />
            </div>
          </div>
        </div>
    </div>
  </div>
</TabPanel>

                    <TabPanel>
                      <div className="it-course-details-wrapper">
                        <div className="it-evn-details-text mb-40">
                        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={woman1}
                eventDate="08"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={woman2}
                eventDate="05"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={woman3}
                eventDate="25"
                eventMonth="April"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={woman4}
                eventDate="08"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
   
          </div>
        </div>
                        </div>

                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="it-course-details-wrapper">
                        <div className="it-evn-details-text mb-40">
                        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={anti1}
                eventDate="08"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={anti2}
                eventDate="05"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={anti3}
                eventDate="25"
                eventMonth="April"
                title="print, and publishing industries for previewing"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
              <SingleEvent
                eventImage={anti4}
                eventDate="08"
                eventMonth="October"
                title="print, and publishing industries for previewing"
              />
            </div>
   
          </div>
        </div>
                        </div>

                      </div>
                    </TabPanel>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default EventMain;
