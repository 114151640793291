import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Breadcrumb from '../../components/Breadcrumb';

import courseImg from '../../assets/img/event/admission.png';
import courseImg2 from '../../assets/application.pdf';

const CourseDetailsMain = () => {
  return (
    <main>
      <Breadcrumb title="Admission" subTitle="admission" />

      <div className="it-course-details-area pt-120 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-8">
              <Tabs className="it-course-details-wrap">
                <div className="it-evn-details-thumb mb-35">
                  <img src={courseImg} alt="" />
                </div>
                {/* <div className="it-evn-details-rate mb-15">
                  <span>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-sharp fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    (4.7)
                  </span>
                </div> */}
                <h4 className="it-evn-details-title mb-0 pb-5">
                B.Sc Nursing
                </h4>
            
                {/* <div className="postbox__meta">
                  <span>
                    <i className="fa-light fa-file-invoice"></i>Lesson 10
                  </span>
                  <span>
                    <i className="fa-light fa-clock"></i>9.00AM- 01.00 PM
                  </span>
                  <span>
                    <i className="fa-light fa-user"></i>Students 20+
                  </span>
                </div> */}

                <div className="it-course-details-nav pb-60">
                  <nav>
                    <TabList
                      className="nav nav-tab"
                      id="nav-tab"
                      role="tablist"
                    >
                      <Tab>
                        <button>Eligibility</button>
                      </Tab>
                      <Tab>
                        <button>Selection For Admission</button>
                      </Tab>
                      <Tab>
                        <button>Admission Requirements</button>
                      </Tab>
                      <Tab>
                        <button>Application Form</button>
                      </Tab>
                    </TabList>
                  </nav>
                </div>
                <div className="it-course-details-content">
                  <div className="tab-content" id="nav-tabContent">
                  <TabPanel>
  <div className="it-course-details-wrapper">
    <div className="it-evn-details-text mb-40">
      {/* <h6 className="it-evn-details-title-sm pb-5">
        Course Description
      </h6> */}
      <p>
<h6>Gender Eligibility:</h6> Only female candidates are eligible to apply.<br/><br/>
<h6>Age Requirement:</h6> Candidates must be between 17 and 25 years old as of December 31st of the admission year.<br/><br/>
<h6>Educational Qualification:</h6>
Must have completed higher secondary schooling (+2) or an equivalent with 12 years of schooling.<br/><br/>
<h6>Subjects required:</h6> English, Physics, Chemistry, and Biology or Botany & Zoology.
Must have obtained at least 45% aggregate marks.
The equivalence of qualifications is determined by The Tamil Nadu Dr. M.G.R. Medical University.<br/><br/>
<h6>Medical Fitness:</h6> Candidates should be medically fit.<br/><br/>
<h6>Eligibility Certificate for Non-Tamil Nadu Candidates:</h6> Non-Tamil Nadu candidates must obtain an eligibility certificate from their respective universities.<br/>

      </p>
    </div>
  </div>
</TabPanel>

                    <TabPanel>
                      <div className="it-course-details-wrapper">
                        <div className="it-evn-details-text mb-40">
                          <p>
                          * Selection for admission will be made based on the academic performance.<br/>
* Selection is purely provisional and is subject to the approval of the Government of Tamilnadu.<br/>
* The Institution reserves the right to make any desirable change rules and policies as the need arises.<br/>
                          </p>
                        </div>

                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="it-course-details-wrapper">
                        <div className="it-evn-details-text mb-40">

                          <p>
                          The following original certificates need to be produced at the time of admission:<br/><br/>

* Mark sheets of the 10th ,11th & 12th  std or equivalent qualification duly attested.<br/>
* Transferred Certificate<br/>
* Community Certificate if the candidates belongs to SC/ST/BC/MBC/DNC.<br/>
* Nativity Certificate<br/>
* Income Certificate<br/>
* Conduct Certificate<br/>
* Medical and physical fitness Certificates from a registered medical practitioner.<br/>
* Migration certificate and Eligibility Certificate (for candidates who have completed their qualifying examination of other universities outside Tamilnadu).<br/>
* Passport size photos 5<br/>
                          </p>
                        </div>

                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="it-course-details-wrapper">
                        <div className="it-evn-details-text mb-40">
                          <p>
                          The following certificates should be enclosed with the Application.<br/><br/>

* Xerox copy of the Transfer Certificate<br/>
* Xerox copy of the 12th Mark sheet<br/>
* Xerox copy of the 11th Mark sheet<br/>
* Xerox copy of the 10th Mark sheet<br/>
* Xerox copy of the Community Certificate<br/>
* Xerox copy of the Nativity Certificate<br/>
* Xerox copy of the Income Certificate<br/>
* Medical Fitness Certificate<br/>
* Xerox copy of the Contact Certificate<br/>
* Xerox copy of the Aadhaar<br/>
                          </p>
                        </div>
                        <div className="it-evn-details-text">
                          <h6 className="it-evn-details-title-sm pb-5">
                          Application Form Download
                          </h6>
                          <a href={courseImg2} download="Application_Form.pdf">
                            <button type="button" className="btn btn-info btn-lg btn-block">
                              Download
                            </button>
                          </a>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </Tabs>
            </div>
            {/* <div className="col-xl-3 col-lg-4">
              <div className="it-evn-sidebar-box it-course-sidebar-box">
                <div className="it-evn-sidebar-thumb mb-30">
                  <img src={courseImg2} alt="" />
                </div>
                <div className="it-course-sidebar-rate-box pb-20">
                  <div className="it-course-sidebar-rate d-flex justify-content-between align-items-center">
                    <span>course fee</span>
                    <span className="rate">
                      $60 <i>$120</i>
                    </span>
                  </div>
                  <i>29-da money-back guarantee</i>
                </div>
                <Link
                  className="ed-btn-square radius purple-4 w-100 text-center mb-20"
                  to="/cart"
                >
                  <span>buy now</span>
                </Link>
                <div className="it-evn-sidebar-list">
                  <ul>
                    <li>
                      <span>4:00 pm 6:00 pm </span> <span>start date</span>
                    </li>
                    <li>
                      <span>enrolled</span>
                      <span>100</span>
                    </li>
                    <li>
                      <span>lectures</span>
                      <span>80</span>
                    </li>
                    <li>
                      <span>skill level</span>
                      <span>Basic</span>
                    </li>
                    <li>
                      <span>className day</span>
                      <span>Monday-friday</span>
                    </li>
                    <li>
                      <span>language</span>
                      <span>English</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </main>
  );
};
export default CourseDetailsMain;
